import React, { useState } from 'react';
import { NavLink,Link } from 'react-router-dom';
import logo from '../Assets/Images/fileflow-main.svg';
// import '../App.css';
// import '../index.css';

function Navbar() {
    const [isCollapsed, setIsCollapsed] = useState(true);
    const toggleNavbar = () => {
        setIsCollapsed(!isCollapsed);
    };
    const closeNavbar = () => {
        setIsCollapsed(true);
    };
    var fileflowLoginUrl = process.env.REACT_APP_FILEFLOW_LOGIN_URL;

  return (
    <>
        <header className="banner-top">
            <div className="header">
                <nav className="navbar  navbar-expand-lg navbar-light  nav-style">
                    <div className="container-fluid">
                        <Link className="navbar-brand" to="/" ><img src={logo} alt="fileflow"/></Link>
                        <button className="navbar-toggler" type="button" onClick={toggleNavbar} >
                            <span className="navbar-toggler-icon"></span>
                        </button>
                        {/* <div className="collapse navbar-collapse justify-content-end" id="navbarSupportedContent"> */}
                        <div className={`collapse navbar-collapse justify-content-end ${isCollapsed ? '' : 'show'}`}  id="navbarSupportedContent">
                            <div className="menu">
                                <ul className="navbar-nav  mb-2 mb-lg-0">
                                    <li className="nav-item">
                                        <NavLink className="nav-link " onClick={closeNavbar} aria-current="page" to="/" >Home</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link " onClick={closeNavbar} aria-current="page" to="/solutions">Solutions</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link " onClick={closeNavbar} aria-current="page" to="/pricing">Pricing</NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink className="nav-link " onClick={closeNavbar} aria-current="page" to="/contact">Contact Us</NavLink>
                                    </li>
                                    <li className="nav-item mt-lg-0 mt-2">
                                        <a className="btn-primary btn-align text-white" conClick={closeNavbar} aria-current="page" href={fileflowLoginUrl} target="_blank">Login</a>
                                    </li>
                                    {/* <li className="nav-item">
                                        <span className="nav-link " aria-current="page" >Customers</span>
                                    </li>
                                    <li className="nav-item">
                                        <span className="nav-link " aria-current="page" >Resources</span>
                                    </li> */}
                                </ul>
                            </div>
                        </div>
                    </div>
                </nav>
            </div> 
        </header>
    </>
  );
}

export default Navbar