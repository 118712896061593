import React, { useEffect,useRef,useState } from 'react';
import '../App.css';
import '../index.css';
import solutions_banner_img from '../Assets/Images/solutions-banner-img.png';
import file_types_img from '../Assets/Images/file-types-img.png';
import client_engag from '../Assets/Images/client-engag.png';
import financial_dat from '../Assets/Images/financial-dat.png';
import solutions_media from '../Assets/Images/solutions-media.png';
import file_secure from '../Assets/Images/file-secure.svg';
import unlimited_pro from '../Assets/Images/unlimited-pro.svg';
import access_cntrl from '../Assets/Images/access-cntrl.svg';
import collabortion_wrk from '../Assets/Images/collabortion-wrk.svg';
import advand_search from '../Assets/Images/advand-search.svg';
import mobile_access from '../Assets/Images/mobile-access.svg';
import auto_awesome from '../Assets/Images/auto-awesome.png';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

function Solutions() {

     var fileflowUrl = process.env.REACT_APP_FILEFLOW_URL;
    console.log(fileflowUrl);
    const [formattedString, setFormattedString] = useState('');

    useEffect(() => {
    // Retrieve the item from localStorage
    const stringFromLocalStorage = localStorage.getItem('sourceParams');

    if (stringFromLocalStorage) {
      try {
        // Parse the string into an object
        const parsedObject = JSON.parse(stringFromLocalStorage);

        // Convert the object to a string with key-value pairs
        const keyValueString = Object.entries(parsedObject)
          .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
          .join('&');

        // Set the formatted string to the state variable
        setFormattedString(keyValueString);
      } catch (error) {
        console.error('Error parsing JSON from localStorage:', error);
      }
    }
  }, []);
   if(formattedString!=''){
        fileflowUrl = fileflowUrl+'&'+formattedString;
         
    }
    
    useEffect(() => {
        // window.scrollTo({top: 0, left: 0, behavior: 'instant'});
    },[])

    return (
        <>
            <Helmet>
                <title>Fileflow | Solutions</title>
                <meta
                    name="description"
                    content="With our file sharing solutions, you can securely store, access, and collaborate on your files from anywhere, at any time. Our cloud-based platform makes it easy to share files with your team, clients, or partners, without having to worry about security breaches or data loss."
                />
                <meta
                    name="keywords"
                    content="Securely share ,collaborate on files ,Cloud-based Platform,Easy to Share Files ,Manage Financial Data ,Secure File Storage,Unlimited Projects,Access Control,Collaborative Workspaces,Advanced Search,Mobile Access,Free Training ,24/7 Expert Support ,Email Support,Receive Large Files"
                />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Fileflow | Solutions"/>
                <meta property="og:description" content="With our file sharing solutions, you can securely store, access, and collaborate on your files from anywhere, at any time. Our cloud-based platform makes it easy to share files with your team, clients, or partners, without having to worry about security breaches or data loss." />
                {/* <meta property="og:image" content="%PUBLIC_URL%/og/Solutions.png" /> */}
            </Helmet>
            <section className="banner-sec">
                <div className="banner-head">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 ">

                                <h1 className='title-solution'><span className="text-primary">Fileflow</span> For All Your Collaborative Needs</h1>

                                <p>Discover how Fileflow can help you overcome your file sharing challenges.</p>

                                <Link to="/pricing"><button className="btn btn-primary btn-align">Get Started For Free</button></Link>
                                <Link to="/pricing" onClick={()=> window.scrollTo({top: 0, left: 0, behavior: 'instant'})}><button className="btn btn plan-btn">View Plans</button></Link>
                            </div>
                            <div className="col-md-6 ">
                                <img src={solutions_banner_img} className="img-fluid" alt="Files Collaborations" />
                            </div>
                        </div>

                    </div>
                </div>


            </section>

            {/* 2nd section */}

            <section className="collaborate-sec">
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <h3 className="text-center">Securely share and collaborate on files anytime, anywhere.</h3>
                            <p className="text-center">With our file sharing solutions, you can securely store, access, and collaborate on your files from anywhere, at any time. Our cloud-based platform makes it easy to share files with your team, clients, or partners, without having to worry about security concerns.</p>
                        </div>
                    </div>
                    <div className="d-flex justify-content-center">
                        <img src={file_types_img} className="" alt="Secure File Collab"/>
                    </div>
                </div>

            </section>

            {/* 3rd section */}

            <section className="solution-types-sec">
                <div className="container-fluid">
                    <div className="row" id='ClientEngagements'>
                        <div className="col-md-6 order-1 order-md-0">
                            <img src={client_engag} className="img-fluid" alt="Client communications"/>
                        </div>

                        <div className="col-md-6  order-0 order-md-1">
                            <div className=" d-flex justify-content-center align-items-center h-100">
                                <div className="solution-text">
                                    <h4>Transform Your Client Engagements</h4>
                                    <p>At Fileflow, we understand the importance of effective communication between your team and clients. That's why we've developed an innovative platform that streamlines client communication and collaboration, fostering a seamless environment for delivering outstanding services and experiences.</p>
                                    <Link to="/pricing"><button className="btn btn-primary btn-align">Get Started For Free</button></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row" id='OrganizationalData'>
                        <div className="col-md-6 ">
                            <div className=" d-flex justify-content-center align-items-center h-100 ">
                                <div className="solution-text">
                                    <h4>Manage Your Organizational Data</h4>
                                    <p>Ensuring the security and accessibility of your organizational data is essential for effective business management. With Fileflow, you can effortlessly manage and securely store all your organizational data in one centralized location, and access it from anywhere, at any time.</p>
                                    <Link to="/pricing"><button className="btn btn-primary btn-align">Get Started For Free</button></Link>
                                </div>
                            </div>

                        </div>
                        <div className="col-md-6">
                            <img src={financial_dat} className="img-fluid" alt="Finacial security"/>
                        </div>
                    </div>
                    <div className="row" id='CreativeAssets'>
                        <div className="col-md-6 order-1 order-md-0">
                            <img src={solutions_media} className="img-fluid" alt="Stream workflow"/>
                        </div>
                        <div className="col-md-6 order-0 order-md-1">
                            <div className=" d-flex justify-content-center align-items-center h-100 ">
                                <div className="solution-text">
                                    <h4>Collaborate On Your <br></br>Creative Assets</h4>
                                    <p>Elevate creative collaboration by offering a secure platform for sharing multimedia assets, promotional materials, and client feedback. Our meticulously designed system ensures a seamless and efficient creative process, empowering your team to deliver unparalleled excellence in every initiative.</p>
                                    <Link to="/pricing"><button className="btn btn-primary btn-align">Get Started For Free</button></Link>
                                </div>
                            </div>
                        </div>

                    </div>


                </div>

            </section>

            {/* 4th section */}

            <section className="file-manage-sec">
                <div className="container">
                    <h4 className="text-center">One platform for all your file management needs</h4>
                    <div className="files-sec">
                        <div className="row">
                            <div className="col-md-4">
                                <img src={file_secure} className="img-fluid" alt="Secure files storage"/>
                                    <h5>Secure File Storage</h5>
                                    <p>Our platform provides secure cloud storage for all your files, ensuring that they are always safe and accessible.</p>
                            </div>
                            <div className="col-md-4">
                                <img src={unlimited_pro} className="img-fluid" alt="Store unlimited projects"/>
                                    <h5>Unlimited Projects</h5>
                                    <p>You can easily store and share files of any size with anyone, anywhere in the world, without any limitations.</p>
                            </div>
                            <div className="col-md-4">
                                <img src={access_cntrl} className="img-fluid" alt="Permissions Access"/>
                                    <h5>Access Control</h5>
                                    <p>Fileflow allows you to set access permissions for each file, so you can control who has access to your files and who doesn't.</p>
                            </div>
                            <div className="col-md-4">
                                <img src={collabortion_wrk} className="img-fluid" alt="Collabrate Workspace"/>
                                    <h5>Collaborative Workspaces</h5>
                                    <p>Our platform offers collaborative workspaces that allow you to work with your team and clients.</p>
                            </div>
                            <div className="col-md-4">
                                <img src={advand_search} alt="Fastest Search Results"/>
                                    <h5>Advanced Search</h5>
                                    <p>Fileflow's advanced search capabilities can easily find any file you need, without wasting time searching through folders.</p>
                            </div>
                            <div className="col-md-4">
                                <img src={mobile_access} alt="Access through Mobile"/>
                                    <h5>Mobile Access</h5>
                                    <p>You can easily access and manage your files on the go, from anywhere, at any time.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* 5th secction */}

            <section className="enterprise-sec">
                <div className="container">
                    <div className="text-center">
                        <span><h4 className="text-white">Checkout Our Subscription Plans<img src={auto_awesome} alt="Best Plans"/></h4></span>
                        <p className="text-white">Tailored for individuals, businesses, and enterprises, our subscription plans provide a strong foundation for efficient data management, integrating essential tools and services to power secure workflows across your organization.
View Plans.</p>
                        <Link to="/pricing" onClick={()=>  window.scrollTo({top: 0, left: 0, behavior: 'instant'})}><button className="btn btn-primary btn-align shadow">View Plans</button></Link>
                    </div>

                </div>
            </section>
        </>
    )
}

export default Solutions