import { Route, BrowserRouter as Router, Routes, Navigate, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';
// import './App.css';
import Navbar from './Components/Navbar';
import Home from './Pages/Home';
import Pricing from './Pages/Pricing';
import Solutions from './Pages/Solutions';
import Footer from './Components/Footer';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Contact from './Pages/Contact';
import axios from "axios";
import crypto from 'crypto-js';
import GetStarted from './Pages/GetStarted';
import ReactGA from 'react-ga';


function App() {
    /*** for marketing******/
     const { search } = useLocation();
     
     if(window.location.hostname=='fileflow.app'){
        ReactGA.initialize('G-8XHBBKE795');
     }
    // console.log(window.location.hostname);
     // Function to parse query parameters from URL
        const getQueryParams = () => {
              const queryParams = new URLSearchParams(decodeURIComponent(window.location.search));
            
              const params = {};
              for (let param of queryParams.entries()) {
                params[param[0]] = param[1];
              }
            console.log(params);
                return params;
        };

    
        // Get query parameters from URL
        const queryParams = getQueryParams();
        // Get query parameters from URL
        const getAppSignature = () => {
            var basepath = process.env.REACT_APP_SOCIALWIRE_URL+'api/addlogs';
            var apiUrl = `${basepath}?appId=${process.env.REACT_APP_APPID}`;
            queryParams['event_value'] = window.location.origin + window.location.pathname;
            queryParams['event_type'] = 2;
            var sortedParams = {};
            Object.keys(queryParams)
                .sort()
                .forEach((key) => {
                sortedParams[key] = queryParams[key];
            }); 

            let paramsUrl = '';

            for (const [key, param] of Object.entries(sortedParams)) {
                if (Array.isArray(param)) {
                    paramsUrl += `&${key}=${JSON.stringify(param)}`;
                } else {
                    paramsUrl += `&${key}=${param}`;
                }
            }
            apiUrl = `${apiUrl}${paramsUrl}`;
            const token = crypto.HmacSHA256(apiUrl, process.env.REACT_APP_APPSECRET).toString(crypto.enc.Hex);
            return token;
        };
        // Get query parameters from URL
        const getparamsData = async () => {
        const getAppSignaturex = getAppSignature();
        const headers = {"APPID": process.env.REACT_APP_APPID,"APPSIGNATURE": getAppSignaturex};
        queryParams['event_value'] = window.location.origin + window.location.pathname;
        queryParams['event_type'] = 2;
        
            try {
                    const response = await axios.post(process.env.REACT_APP_SOCIALWIRE_URL+'api/addlogs', queryParams, { headers });
                    if (response.data.status === 1) {
                        console.error("log added");
                    } else {

                    }
                } catch (error) {
                    console.error("Error:", error);
                }
        };
        const campQueryParams = new URLSearchParams(window.location.search);
        if(campQueryParams!=''){
           
           getparamsData();
        }
      
   
   useEffect(() => {
    // Check if "queryParams" exist in the URL\
       const queryParams = new URLSearchParams(search);
     
    if (queryParams.size > 0) {
       // const queryParamsnew = getQueryParams();
        // Store the query parameters in localStorage
        localStorage.setItem('sourceParams', JSON.stringify(queryParams));
    }
    }, [search]);
    

    const location = useLocation();
    const ScrollToSection = () => {
        const scrollToSelector = location.hash;
        const offset = 100; // Adjust this value according to your needs
    
        if (scrollToSelector) {
            const targetElement = document.getElementById(scrollToSelector.substring(1));
            if (targetElement) {
                const topPos = targetElement.getBoundingClientRect().top + window.pageYOffset - offset;
                window.scrollTo({ top: topPos, behavior: 'smooth' });
            }
        } else {
            window.scrollTo({ top: 0, left: 0, behavior: 'instant' });
        }
    }


  useEffect(() => {
    ScrollToSection();
  }, [location]);

  return (
    
    <HelmetProvider>

      {/* <Router> */}
      <Helmet>
        <title>Fileflow | Home</title>

        <meta
          name="description"
          content="Fileflow empowers seamless and secure sharing of your work and ideas using instant shareable links. Effortlessly upload files from your computer, ensuring easy saving and sharing."
        />
        <meta
          name="keywords"
          content="Cloud Storage ,Online File Storage,File Sharing and Collaboration,Secure Data Storage,Document Management,Cloud Backup Solutions,Virtual File Drive,Cloud Syncing Services,Access Files Anywhere,Online Document Repository,File Hosting Platform,Team Collaboration Tools,Cloud Workspace,Data Security and Privacy, Cross-Platform File Syncing,Cloud-Based Document Management,Centralized File Storage,Online Content Sharing,Document Version Control,24/7 Expert Support ,Email Support,Receive Large Files,Collaborative Workspaces,Advanced Search,Mobile Access,Unlimited Projects,Access Control,simple, Drag and Drop,User Groups "
        />
        <meta property="og:type" content="website"/>
        <meta
          property="og:title"
          content="Fileflow | Home"
        />
        <meta
          property="og:description"
          content="Fileflow empowers seamless and secure sharing of your work and ideas using instant shareable links. Effortlessly upload files from your computer, ensuring easy saving and sharing."
        />
        {/* <meta property="og:image" content="%PUBLIC_URL%/og/Home.png" /> */}

      </Helmet>
        
          <Routes>
          
            <Route exact path="/" element={<><Navbar /><Home/><Footer ScrollToSection={ScrollToSection}/></>} />
            <Route path="/pricing" element={<><Navbar /><Pricing /><Footer ScrollToSection={ScrollToSection}/></>} />
            <Route path="/solutions" element={<><Navbar /><Solutions/><Footer ScrollToSection={ScrollToSection}/></>} />
            <Route path="/contact" element={<><Navbar /><Contact/><Footer ScrollToSection={ScrollToSection}/></>} />
            <Route path="/getstarted" element={<><GetStarted/></>} />
            <Route path='*' element={<Navigate to='/' />} />
          </Routes>
        
      {/* </Router> */}
    </HelmetProvider>
  );
}



export default App;
