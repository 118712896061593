import { Player } from "@lottiefiles/react-lottie-player";
import '../App.css';
import '../index.css';
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import Swal from "sweetalert2";
import bullet from "../Assets/Images/bullet.png";
import customplay from '../Assets/Images/enterprise.gif';
// import { AES,enc } from 'crypto-js';
// import bcrypt from 'bcryptjs'
import md5 from "md5";
import { Link } from "react-router-dom";

function Pricing() {

  //const fileflowUrl = process.env.REACT_APP_FILEFLOW_URL;
  const pricingURL = process.env.REACT_APP_FILEFLOW_API_URL + "/pricing";
  const apiKey = process.env.REACT_APP_API_KEY;

  const [toggleButton, setToggleButton] = useState("yearly");
  const [plans, setPlans] = useState([]);
  const [PlanDetails, setPlanDetails] = useState([]);

  // const encryptedBackendKey = AES.encrypt(pricingURL, apiKey).toString();
  const timestamp = Date.now();
  const key = timestamp + apiKey;
  const encryptedBackendKey = md5(key);

  const headers = {
    "X-Secret-Key": encryptedBackendKey,
  };
  const data = {
    timestamp: timestamp,
  };

  const getPricing = async () => {
    try {
      const response = await axios.post(pricingURL, data, { headers });
      // console.log('Response:', response.data);
      if (response.data.status === 1) {
        setPlanDetails(response.data.data);
        setPlans(response.data.data.monthlyPlans);
      } else {
        Swal.fire({
          icon: "error",
          title: response.data.message,
          // text: error,
          timer: 3000,
        });
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    getPricing();
    window.scrollTo({top: 0, left: 0, behavior: 'instant'});
  }, []);

  useEffect(() => {
    if (toggleButton === "yearly") {
      setPlans(PlanDetails.yearlyPlans);
    } else {
      setPlans(PlanDetails.monthlyPlans);
    }
  }, [toggleButton,PlanDetails]);

  const covertToGB = (size) => {
    return size / 1024 / 1024 / 1024 + " GB";
  };
 var fileflowUrl = process.env.REACT_APP_FILEFLOW_URL;
//    const [formattedString, setFormattedString] = useState('');
//
//    useEffect(() => {
//    // Retrieve the item from localStorage
//    const stringFromLocalStorage = localStorage.getItem('sourceParams');
//
//    if (stringFromLocalStorage) {
//      try {
//        // Parse the string into an object
//        const parsedObject = JSON.parse(stringFromLocalStorage);
//
//        // Convert the object to a string with key-value pairs
//        const keyValueString = Object.entries(parsedObject)
//          .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
//          .join('&');
//
//        // Set the formatted string to the state variable
//        setFormattedString(keyValueString);
//      } catch (error) {
//        console.error('Error parsing JSON from localStorage:', error);
//      }
//    }
//  }, []);
//    if(formattedString!=''){
//        fileflowUrl = fileflowUrl+'&'+formattedString;
//    }
    const queryParams = new URLSearchParams(decodeURIComponent(window.location.search));
            
     
   
    if(queryParams.size > 0){
        fileflowUrl = fileflowUrl+'&'+queryParams;
    }

  return (
    <>
      <Helmet>
        <title>Fileflow | Pricing</title>
        <meta
          name="description"
          content="Select the ideal plan for your requirements and budget. Choose from yearly or monthly payment options. We offer Standard, Premium, and Professional plans"
        />
        <meta
          name="keywords"
          content="Standard plan , Premium plan,Professional plan , Billed Monthly , Billed yearly , Trial period,Perfect plan for starters,Perfect plan for teams ,Perfect plan for companies,Unlimited Projects,Sync across devices,Everything in Pro Plan,Admin Tools."
        />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Fileflow | Pricing" />
        <meta
          property="og:description"
          content="Select the ideal plan for your requirements and budget. Choose from yearly or monthly payment options. We offer Standard, Premium, and Professional plans"
        />
        {/* <meta property="og:image" content="%PUBLIC_URL%/og/pricing.png" /> */}
      </Helmet>
      {/* 1st section */}
      <section className="plan-content" id="plans">
        <div className="container">
          <div className="content">
            <div className="row">
              <div className="col-12">
                <h1>
                  Pricing Plans for
                  <span className="text-primary"> Fileflow</span>
                </h1>
              </div>
              <div className="col-12">
                <p>Choose the plan that fits your needs and budget.</p>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="primary">
          <div className="switch">
            <input
              name="radio"
              type="radio"
              value="optionone"
              id="optionone"
              defaultChecked
            />
            <label
              htmlFor="optionone"
              className={toggleButton === "monthly" ? "left select" : "left"}
              onClick={() => setToggleButton("monthly")}
            >
              Billed Monthly
            </label>

            <input name="radio" type="radio" value="optiontwo" id="optiontwo" />
            <label
              htmlFor="optiontwo"
              className={toggleButton === "yearly" ? "right select" : "right"}
              onClick={() => setToggleButton("yearly")}
            >
              Billed Yearly
            </label>

            <span
              aria-hidden="true"
              className={toggleButton === "yearly" ? "right" : ""}
            ></span>
          </div>
        </div> */}
        {/* <div className="text-center discount">
          {toggleButton === "yearly" &&
            <span class="badge badge-pill my-3">Save 20%</span>
          }
        </div> */}

      </section>
      {/* 2nd section */}

      <section className="plan-types-sec">
        <div className="container-fluid">
          <div className="plans">
            {plans ? (
              <div className="row px-md-3 px-2">
                {plans[0] ? (

                  <div className="col-xl-3 col-lg-12">
                    <div className="plan-type-align">
                      <div className="basic-plan">
                        <div className="d-flex justify-content-center">
                          <Player
                            src="https://assets4.lottiefiles.com/packages/lf20_uu0x8lqv.json"
                            background="transparent"
                            speed="1"
                            style={{ width: 90 + "px", height: 90 + "px" }}
                            loop
                            autoplay
                          ></Player>
                        </div>
                        <div className="pricing-container">
                          <h5>{plans[0]?.subscription_name}</h5>
                          {/* <p>{plans[0]?.description}</p> */}
                          <p className="plans-content">For starters or individuals.</p>
                          <div class="price-wrapper">
                          {/* {plans[0]?.tenure_type_id === 1 && (
                              <div class="price-slash">
                                <h3 className="text-secondary">${plans[0]?.actual_amount}</h3> 
                              </div>
  )} */}
                          </div>
                          <h3 className="text-primary">
                          $11
                            {/* ${parseFloat(plans[0]?.amount).toFixed(2)} */}
                          <div class="tool-bx">
                                                  <i class="fa-solid fa-circle-info"></i>
                                                  <div class="tooltip">$17.99 when paid monthly.  </div>
                                                </div>
                          </h3>
                          <p className="pb-4 plans-content">
                            {plans[0]?.tenure_type_id === 1
                              ? "Per Month / Paid Annually."
                              : "Per Month / Paid Annually."}
                                                  

                          </p>
                          </div>
                          <a href={fileflowUrl+"&plan="+plans[0]?.subscription_name} target="_blank" className="btn btn-primary btn-align">Get Started For Free</a>
                          {/* <button className="btn btn-outline-primary text-primary current-btn">Current Plan</button> */}

                        <ul>
                        <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span><b>Included storage space of 100 GB.</b></span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span><b>3 Workspace Members</b></span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>Unlimited External Collaborators.</span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>Folders and subfolder organization.</span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>Unlimited User Groups.</span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>Granular File Permissions.</span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>Access Expiration & Passwords.</span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>Commenting & Notification.</span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>Single Sign On</span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>24/7 Technical Support</span>
                            </div>
                          </li>               
                          {/*                         
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span> */}
                              {/* <span>10 GB Free</span> */}
                              {/* <span>
                                {covertToGB(plans[0]?.available_storage)}
                              </span> */}
                            {/* </div>
                          </li> */}
                         
                        </ul>
                      </div>
                    </div>
                  </div>

                ) : null}
                {plans[1] ? (
                  
                  <div className="col-xl-3 col-lg-12">
                    <div className="plan-type-align">
                      <div className="pro-sec">
                        <div className="d-flex justify-content-center">
                          <button className="btn btn-light text-primary recmd-btn">
                            {/* <span className="material-symbols-outlined text-primary">
                              temp_preferences_custom
                            </span> */}
                            Recommended
                          </button>
                        </div>
                        <div className="pro-plan">
                          <div className="plans-img">
                            <div className="d-flex justify-content-center">
                              <Player
                                src="https://assets2.lottiefiles.com/packages/lf20_bYEwvM.json"
                                background="transparent"
                                speed="1"
                                style={{ width: 90 + "px", height: 90 + "px" }}
                                loop
                                autoplay
                              ></Player>
                            </div>
                            <div className="pricing-container">
                              <h5 className="text-white">
                                {plans && plans[1]?.subscription_name}
                              </h5>
                              <p className=" plans-content text-white">For small teams.</p>
                              <div class="price-wrapper">
                              {/* {plans[1]?.tenure_type_id === 1 && (
                              <div class="price-slash">
                                <h3 className="text-white">${plans[1]?.actual_amount}</h3> 
                              </div>
                              )} */}
                          </div>
                              <h3 className="text-primary text-white">
                              $31
                                {/* ${parseFloat(plans[1]?.amount).toFixed(2)} */}
                              <div class="tool-bx">
                                                  <i class="fa-solid fa-circle-info"></i>
                                                  <div class="tooltip">$45.99 when paid monthly.</div>
                                                </div>
                              
                              </h3>
                              <p className=" pb-4 plans-content text-white">
                                {plans[0].tenure_type_id === 1
                                  ? "Per Month / Paid Annually."
                                  : "Per Month / Paid Annually."}
                              </p>
                              </div>
                            <a href={fileflowUrl+"&plan="+plans[1]?.subscription_name} target="_blank" className="btn btn-primary btn-align">Get Started For Free</a>
                            {/* <button className="btn btn-light text-primary upgrd-btn">Upgrade Plan</button> */}
                          </div>

                          <ul>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span><b>250 GB Included Storage Space.</b></span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span><b>10 Workspace Members</b></span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>Unlimited External Collaborators.</span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>Folders and subfolder organization.</span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>Unlimited User Groups.</span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>Granular File Permissions.</span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>Access Expiration & Passwords.</span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>Commenting & Notification.</span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>Single Sign On</span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>24/7 Technical Support</span>
                            </div>
                          </li>
                          {/* <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span><b>Product Partner: Access to all new features without additional cost.</b></span>
                            </div>
                          </li> */}
                           
                            {/* <li>
                              <div className="done-item">
                                <span className="material-symbols-outlined">
                                  done
                                </span>
                                <span>
                                  {covertToGB(plans[1]?.available_storage)}
                                </span>
                              </div>
                            </li> */}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>

                ) : null}
                {plans[2] ? (
                  <>
                  <div className="col-xl-3 col-lg-12">
                    <div className="plan-type-align">
                      <div className="enterprise-plan">
                        <div className="enterprise-text">
                          <div className="d-flex justify-content-center">
                            <Player
                              src="https://assets3.lottiefiles.com/packages/lf20_ABViugg18Y.json"
                              background="transparent"
                              speed="1"
                              style={{ width: 90 + "px", height: 90 + "px" }}
                              loop
                              autoplay
                            ></Player>
                          </div>
                          <div className="pricing-container">
                          <h5>{plans[2]?.subscription_name}</h5>
                          <p className="plans-content">For medium-sized teams.</p>
                          <div class="price-wrapper">
                          {/* {plans[2]?.tenure_type_id === 1 && (
                            <div class="price-slash">
                              <h3 className="text-secondary">${plans[2]?.actual_amount}</h3> 
                            </div>
                          )} */}
                        </div>
                          <h3 className="text-primary">
                          $119
                            {/* ${parseFloat(plans[2]?.amount).toFixed(2)} */}
                          <div class="tool-bx">
                                                <i class="fa-solid fa-circle-info"></i>
                                                <div class="tooltip">$149.99 when paid monthly.  </div>
                                               </div>
                          </h3>
                          <p className="pb-4 plans-content">
                            {plans[2].tenure_type_id === 1
                              ? "Per Month / Paid Annually."
                              : "Per Month / Paid Annually."}
                          </p>
                          </div>
                          <a href={fileflowUrl+"&plan="+plans[2]?.subscription_name} target="_blank" className="btn btn-primary btn-align">Get Started For Free</a>
                          {/* <button className="btn btn-primary text-white enterprise-btn">Upgrade Plan</button> */}
                        </div>
                        <ul>
                        <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span><b>1 TB Included Storage Space.</b></span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span><b>25 Workspace Members</b></span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>Unlimited External Collaborators.</span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>Folders and subfolder organization.</span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>Unlimited User Groups.</span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>Granular File Permissions.</span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>Access Expiration & Passwords.</span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>Commenting & Notification.</span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>Single Sign On</span>
                            </div>
                          </li>
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>24/7 Technical Support</span>
                            </div>
                          </li>
                          {/* <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span><b>Product Partner: Access to all new features without additional cost.</b></span>
                            </div>
                          </li> */}
                          
                          {/* <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>
                                {covertToGB(plans[2]?.available_storage)}
                              </span>
                            </div>
                          </li> */}
                          
                        </ul>
                      </div>
                    </div>
                  </div>
                  
                  <div className="col-xl-3 col-lg-12">
                    <div className="plan-type-align">
                      <div className="enterprise-plan">
                        <div className="enterprise-text">
                          <div className="d-flex justify-content-center">
                            <img id="box-shape" className="customplay img-fuild" src={customplay} alt="bounce" />
                          </div>
                          <div className="pricing-container">
                            <h5 className="">Enterprise</h5>
                            <p className="plans-content">For large teams with high volume storage needs.</p>
                            <h6 className="custom-text text-dark pb-3 m-0">Customized for Your Needs</h6>
                            <span className="text-center"><p className="mx-md-4 mx-3 pb-3 plans-content">Do you need to add more workspace users or need additional storage? Contact Us now.</p></span>
                          </div>
                          <Link className="btn btn-primary btn-align" onClick={()=> window.scrollTo({top: 0, left: 0, behavior: 'instant'})} to="/contact">Reach a Specialist</Link>
                          

                          {/* <p className="">
                            {plans[2].tenure_type_id === 1
                              ? "yearly"
                              : "Monthly"}
                          </p> */}
                          {/* <button className="btn btn-primary text-white enterprise-btn">Upgrade Plan</button> */}
                        </div>
                        {/* <ul>
                         
                        
                          <li>
                            <div className="done-item">
                              <span className="material-symbols-outlined">
                                done
                              </span>
                              <span>
                                {covertToGB(plans[2]?.available_storage)}
                              </span>
                            </div>
                          </li>
                          
                        </ul> */}
                      </div>
                    </div>
                  </div>
                  </>
                ) : null}
              </div>
            ) : (
              <div className="loader">
                {/* <img src={loader}  alt="arrow"alt='loader'/> */}
                <div className="line line-1"></div>
                <div className="line line-2"></div>
                <div className="line line-3"></div>
                <div className="line line-4"></div>
                <div className="line line-5"></div>
              </div>
            )}
          </div>
        </div>
      </section>

      {/* 3rd section */}
      <section className="question-sec">
        <div className="container">
          <h2 className="text-center qus-text">Frequently asked questions</h2>
       
          <div className="accordion" id="accordionExample">
          <hr/>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingOne">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseOne"
                  aria-expanded="false"
                  aria-controls="collapseOne"
                >
                  How can I upgrade or downgrade my plan?
                </button>
              </h2>
              <div
                id="collapseOne"
                className="accordion-collapse collapse"
                aria-labelledby="headingOne"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body upgrade-list">
                  To Upgrade your subscription plan:
                  <br />
                  <ul className="p-3">
                    <li className="mt-2">
                      <span className="bullet-icon me-2">
                        <img src={bullet}  alt="arrow"/>
                      </span>
                      Navigate to the <b>Subscription</b> section in the side
                      menu.
                    </li>
                    <li>
                      <span className="bullet-icon me-2">
                        <img src={bullet}  alt="arrow"/>
                      </span>
                      Select your desired plan and click on <b>Upgrade Plan</b>.
                    </li>
                    <li>
                      <span className="bullet-icon me-2">
                        <img src={bullet}  alt="arrow"/>
                      </span>
                      Provide your billing details and proceed to <b>payment</b>
                      .
                    </li>
                  </ul>
                  To switch to a lower-tier plan, simply follow these steps:
                  <ul className="p-3">
                    <li className="mt-2">
                      <span className="bullet-icon me-2">
                        <img src={bullet}  alt="arrow"/>
                      </span>
                      Go to the <b>Subscription</b> section in the side menu
                      after logging in.
                    </li>
                    <li>
                      <span className="bullet-icon me-2">
                        <img src={bullet}  alt="arrow"/>
                      </span>
                      Click on <b>Downgrade Plan</b> to initiate the process
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <hr />
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingTwo">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                >
                  What happens if I exceed my storage?
                </button>
              </h2>
              <div
                id="collapseTwo"
                className="accordion-collapse collapse"
                aria-labelledby="headingTwo"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                When you exceed your storage capacity, you will receive a notification and an email alert. You can then upgrade your plan via the Subscription page to have more storage space. Keep in mind that while you won't be able to upload new files, you'll still have access to the files you've already uploaded.
                </div>
              </div>
            </div>
            <hr />

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingThree">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseThree"
                  aria-expanded="false"
                  aria-controls="collapseThree"
                >
                  Is there a limit on the number of files I can share or store?
                </button>
              </h2>
              <div
                id="collapseThree"
                className="accordion-collapse collapse"
                aria-labelledby="headingThree"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                There are no restrictions on file sharing, but the ability to create workspace user accounts is limited depending on your plan. Additionally, your storage capacity is determined by your subscription, allowing you to store files up to that allocated limit.
                </div>
              </div>
            </div>
            <hr />

            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button
                  className="accordion-button collapsed"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                >
                  How can I cancel my subscription?
                </button>
              </h2>
              <div
                id="collapseFour"
                className="accordion-collapse collapse"
                aria-labelledby="headingFour"
                data-bs-parent="#accordionExample"
              >
                <div className="accordion-body">
                  Certainly, you have the option to terminate your{" "}
                  <b>subscription</b> at any time by following these steps:
                  <br />
                  <ul className="p-3">
                    <li className="mt-2">
                      <span className="bullet-icon me-2">
                        <img src={bullet}  alt="arrow"/>
                      </span>
                      Navigate to the <b>Subscription</b> section in the side
                      menu.
                    </li>
                    <li>
                      <span className="bullet-icon me-2">
                        <img src={bullet}  alt="arrow"/>
                      </span>
                      Click on the <b>Cancel Subscription</b> button that comes
                      below My Subscription.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <hr />
          </div>
        </div>
      </section>
    </>
  );
}

export default Pricing;
