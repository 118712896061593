import React, { useEffect,useState } from 'react';
import '../App.css';
import '../index.css';
import banner_img from '../Assets/Images/banner-img.png';
import fails from '../Assets/Images/fails.png';
import box from '../Assets/Images/box.png';
import dashboard from '../Assets/Images/dashboard.png';
import frictionless from '../Assets/Images/Frictionless.png';
import receive from '../Assets/Images/receive.png';
import management from '../Assets/Images/management.png';
import back_up from '../Assets/Images/back-up.png';
import suppor_icon_a from '../Assets/Images/suppor-icon-a.svg';
import suppor_icon_b from '../Assets/Images/suppor-icon-b.svg';
import suppor_icon_c from '../Assets/Images/suppor-icon-c.svg';
import imgfile from '../Assets/Images/File.svg';
import verifiedimg from '../Assets/Images/verified.svg';
import support from '../Assets/Images/support.png';
import ui_face from '../Assets/Images/user-interface.png';
import AOS from 'aos';
import "aos/dist/aos.css";
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import Navbar from '../Components/Navbar';


function Home() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
    window.scrollTo({top: 0, left: 0, behavior: 'instant'});
  }, []);
    var fileflowUrl = process.env.REACT_APP_FILEFLOW_URL;
    const [formattedString, setFormattedString] = useState('');

    useEffect(() => {
    // Retrieve the item from localStorage
    const stringFromLocalStorage = localStorage.getItem('sourceParams');

    if (stringFromLocalStorage) {
      try {
        // Parse the string into an object
        const parsedObject = JSON.parse(stringFromLocalStorage);

        // Convert the object to a string with key-value pairs
        const keyValueString = Object.entries(parsedObject)
          .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
          .join('&');

        // Set the formatted string to the state variable
        setFormattedString(keyValueString);
      } catch (error) {
        console.error('Error parsing JSON from localStorage:', error);
      }
    }
  }, []);
    if(formattedString!=''){
        fileflowUrl = fileflowUrl+'&'+formattedString;
    }
   
  return (
      
    <>
       <Helmet>
          <title>Fileflow | Home</title>
            <meta
                name="description"
                content="Fileflow empowers seamless and secure sharing of your work and ideas using instant shareable links. Effortlessly upload files from your computer, ensuring easy saving and sharing."
            />
            <meta
                name="keywords"
                content="Cloud Storage ,Online File Storage,File Sharing and Collaboration,Secure Data Storage,Document Management,Cloud Backup Solutions,Virtual File Drive,Cloud Syncing Services,Access Files Anywhere,Online Document Repository,File Hosting Platform,Team Collaboration Tools,Cloud Workspace,Data Security and Privacy, Cross-Platform File Syncing,Cloud-Based Document Management,Centralized File Storage,Online Content Sharing,Document Version Control,24/7 Expert Support ,Email Support,Receive Large Files,Collaborative Workspaces,Advanced Search,Mobile Access,Unlimited Projects,Access Control,simple, Drag and Drop,User Groups "
            />
            <meta property="og:type" content="website" />
            <meta property="og:title" content="Fileflow | Home"/>
            <meta property="og:description" content="Fileflow empowers seamless and secure sharing of your work and ideas using instant shareable links. Effortlessly upload files from your computer, ensuring easy saving and sharing." />
            {/* <meta property="og:image" content="%PUBLIC_URL%/og/Home.png" /> */}
      </Helmet>
      <section className="top-banner">
        <div className="container">
          <div className="text-center">
            <div className="row">
              <div className="col-md-12">
                <div className="mb-5">
                  <h6>Introducing Fileflow</h6>
                  <h1>A new way to <span ></span></h1>
                  <p>Fileflow allows you to securely share your work and ideas through instantly shareable links.</p>
                  <Link to="/pricing"><button className="btn btn-primary btn-align">Get Started For Free</button></Link>
                </div>
                <img data-aos="fade-up" className="img-fluid" src={banner_img} alt="Fileflow Login"/>
              </div>
            </div>
          </div>
        </div>
      </section>



      <section className="drag-and-drop">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2>Simply Drag and Drop <span>Files</span> </h2>
              <p>Easily drop files from your computer or upload them, to seamlessly save and share.</p>
            </div>
            <div className="col-md-12">
              <div className="files-drop">
                <div className="files-Bg"> <img className="folder-files" src={fails} alt='File Types'/></div>
                <img id="box-shape" className="box" src={box} alt='Fileflow cube' />
              </div>
              <div className="d-flex justify-content-center " style={{zIndex: 99, position: 'relative'}}>
                <div className="col-12 col-lg-9">
                  <div className="upload">
                    <div className="file-uploaded">
                    <div className="file-upld-icon"><img src={imgfile} alt='upload icon'/></div>
                      <h5>File Uploaded</h5>
                    </div>
                    <div className="file-upld-icon"><img src={verifiedimg} alt='upload successfully'/></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>





      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-6 ">
              <div data-aos="zoom-in" className="d-flex justify-content-center align-items-center h-100">
                <div className="service-text">
                  <h3 className="">Brand Personalization</h3>
                  <p className="mb-4">Make your platform truly yours by customizing it with your brand's unique identity. Fileflow allows you to seamlessly integrate your corporate identity into the platform. Elevate your professional image and ensure a consistent brand experience for both your team and clients.</p>
                  <Link to="/pricing"><button className="btn btn-primary btn-align">Get Started For Free</button></Link>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 p-0 ">
              <div className="service-img">
                <img data-aos="zoom-in" className="img-fluid" src={dashboard} alt="Files Collaboration"/>
              </div>
            </div>
          </div>
        </div>
      </section>


      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-6 p-0 order-1 order-lg-0">
              <div className="service-Receive">
                <img data-aos="zoom-in" className="img-fluid" src={receive} alt="Large Files Manage"/>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 order-0 order-lg-1">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div data-aos="zoom-in" className="service-text sub-service-text">
                  <h3 className="">Effortless Teamwork</h3>
                  <p className="mb-4">In the heartbeat of your operations lies the need for seamless teamwork, connecting diverse groups such as teams, clients and partners. Fileflow provides a unified space where everyone collaborates effortlessly on your critical data, ensuring both productivity and security.</p>
                  <Link to="/pricing"><button className="btn btn-primary btn-align">Get Started For Free</button></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="Frictionless">
        <div className="container-fluid h-100">
          <div className="row h-100">
            <div className="col-md-12 col-lg-6 ">
              <div data-aos="zoom-in" className="d-flex justify-content-center align-items-center  h-100">
                <div className="service-text">
                  <h3 className="">Refined Security</h3>
                  <p className="mb-4">Ensuring the security of your critical files is our utmost priority. We've implemented sophisticated security measures to guarantee the protection of your information. Our goal is to provide a secure layer around your data, giving you peace of mind as you handle your information.</p>
                  <Link to="/pricing"><button className="btn btn-primary btn-align">Get Started For Free</button></Link>
                </div>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 p-0">
              <div className="d-flex justify-content-center align-items-center h-100 image-container"><img data-aos="zoom-in" src={frictionless} alt="File Security"/></div>
            </div>
          </div>
        </div>
      </section>

     
      <section>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12 col-lg-6 p-0 order-1 order-lg-0">
              <div className="user-interface">
                <img data-aos="zoom-in" className="img-fluid" src={ui_face} alt="Large Files Manage"/>
              </div>
            </div>
            <div className="col-md-12 col-lg-6 order-0 order-lg-1">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div data-aos="zoom-in" className="service-text sub-service-text ui-text">
                  <h3 className="">User-Friendly Interface</h3>
                  <p className="mb-4">Navigating through your work should be a breeze. With an intuitive and user-friendly interface, our platform ensures that accessing and managing your data is effortlessly efficient. Streamlining your experience adds an extra layer of convenience to your day-to-day operations.</p>
                  <Link to="/pricing"><button className="btn btn-primary btn-align">Get Started For Free</button></Link>
                </div>
              </div>
            </div>

          </div>
        </div>
      </section>


      <section className="management-section">
        <div className="container">
          <div className="d-flex justify-content-center text-center available">
            <div className="row mb-2 mb-md-4  px-2 px-sm-0">
              <div className="col-md-12 ">
                <h3 className="">Tailored To Your Needs</h3>
                <p className="mb-2 mb-md-4">A versatile storage solution addressing diverse requirements.</p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-md-6">
              <div data-aos="zoom-in" className="management">
                <h5 className="mb-4">A file management and collaboration solution that suits any budget.</h5>
                <Link to="/pricing" onClick={()=> window.scrollTo({top: 0, left: 0, behavior: 'instant'})}>Learn More <span className="material-symbols-outlined ms-2">arrow_forward</span></Link>
                <img src={management} alt="Budget Price"/>
              </div>

            </div>

            <div className="col-md-6">
              <div data-aos="zoom-in" className="back-up">
                <h5 className="mb-4">Ensure your team files are securely stored and accessible anytime, anywhere.</h5>
                <Link to="/solutions" onClick={()=> window.scrollTo({top: 0, left: 0, behavior: 'instant'})}>Learn More <span className="material-symbols-outlined ms-2">arrow_forward</span></Link>
                <img src={back_up} alt="upload files"/>
              </div>

            </div>
          </div>

        </div>
      </section>



      <section className=" support" >
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="d-flex h-100 align-items-center">
                <div>
                  <h3 className="">24/7 Expert Support</h3>
                  <p>At Fileflow, we understand the importance of immediate assistance. That's why our live chat support takes center stage, offering you a direct and real-time channel for quick and seamless communication.</p>
                  <div className="icons">
                    <ul>
                      <li data-aos="fade-up"><span><img src={suppor_icon_a} alt="Services" /></span>Included with all Subscriptions</li>
                      <li data-aos="fade-up"><span><img src={suppor_icon_b} alt="Rating" /></span>Dedicated Customer Support Team</li>
                      <li data-aos="fade-up"><span><img src={suppor_icon_c} alt="Customer support" /></span>Tickets & Live Chat Support</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 mt-4 mt-sm-0">
              <div className="w-100 text-center"><img src={support} alt="Customer Support Team"/></div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}


export default Home

