import React, { useEffect,useState } from 'react'
import '../App.css';
import '../index.css';
import contactus from '../Assets/Images/contactus.png';
import formImg from '../Assets/Images/form.png';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import Swal from 'sweetalert2';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import md5 from 'md5';
import ReCAPTCHA from "react-google-recaptcha";
import PhoneInput from 'react-phone-number-input';
import { isValidPhoneNumber } from 'react-phone-number-input';

function Contact() {

     var fileflowUrl = process.env.REACT_APP_FILEFLOW_URL;
     const [formattedString, setFormattedString] = useState('');
     const [phone, setphone] = useState('');

    useEffect(() => {
        // Retrieve the item from localStorage
        const stringFromLocalStorage = localStorage.getItem('sourceParams');

        if (stringFromLocalStorage) {
            try {
                // Parse the string into an object
                const parsedObject = JSON.parse(stringFromLocalStorage);

                // Convert the object to a string with key-value pairs
                const keyValueString = Object.entries(parsedObject)
                  .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
                  .join('&');

                // Set the formatted string to the state variable
                setFormattedString(keyValueString);
              } catch (error) {
                console.error('Error parsing JSON from localStorage:', error);
              }
        }
  }, []);
   if(formattedString!=''){
        fileflowUrl = fileflowUrl+'&'+formattedString;
    }
    const contactFormURL = process.env.REACT_APP_FILEFLOW_API_URL+'/contactformsubmit';

    const apiKey = process.env.REACT_APP_API_KEY;
    const recaptchaRef = React.useRef();

    useEffect(() => {
        window.scrollTo({top: 0, left: 0, behavior: 'instant'});
    },[])

    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        trigger,
        reset
    } = useForm();

    const timestamp = Date.now();
    const key= timestamp+apiKey;
    const encryptedBackendKey = md5(key)


    const headers = {
        'X-Secret-Key': encryptedBackendKey,
    };


    const onSubmit = async (formData) => { 
        const token = await recaptchaRef.current.executeAsync();
        try {
          const data = {...formData, ...{timestamp},...{token}}
          const response = await axios.post(contactFormURL, data, { headers });

        //   console.log(data);
          // console.log(response.data);
          if(response.data.status === 1){
            Swal.fire({
                icon: 'success',
                title: response.data.message,
                // title: "",
                // text: 'Thank you for your submission.',
                timer: 3000,
              })
              reset();
          }else{
            Swal.fire({
              icon: 'error',
              title: 'Error in Inquiry Submit',
              text: response.data.message,
              timer: 3000,
            })
          }
          
        //   reset();
        } catch (error) {
          Swal.fire({
            icon: 'error',
            title: 'Error in Inquiry Submit',
            // text: error,
            timer: 3000,
          })
          console.error('Error submitting the form:', error);
        }
    };

    const handleInputChange = (name, value) => {
        setValue(name, value); // Update the form value
        trigger(name); // Trigger validation for the field
        console.log(value);
    };

    return (
        <>
            <Helmet>
                <title>Fileflow | Contact</title>
                <meta
                    name="description"
                    content="Reach out to our dedicated support team for prompt assistance, answers to your questions, and personalized solutions."
                />
                <meta
                    name="keywords"
                    content="Cloud Storage ,Online File Storage,File Sharing and Collaboration,Secure Data Storage,Free Training ,24/7 Expert Support ,Email Support,Personalized solutions."
                />
                <meta property="og:type" content="website" />
                <meta property="og:title" content="Fileflow | Contact"/>
                <meta property="og:description" content="Reach out to our dedicated support team for prompt assistance, answers to your questions, and personalized solutions." />
                {/* <meta property="og:image" content="%PUBLIC_URL%/og/contactus.png" /> */}
            </Helmet>
            <section className="banner-sec">
                <div className="banner-head contact-banner cont-banner ">
                    <div className="container">
                        <div className="row">
                            <div className="col-md-6 d-flex  align-items-center">
                                <div class="contact-team">
                                    <h1>Connect with our Customer Success team today.</h1>
                                    <p>Reach out to our dedicated support team for prompt assistance and answers to your questions.</p>

                                    {/* <a href={fileflowUrl} target="_blank"><button className="btn btn-primary btn-align">Get Started</button></a>
                                    <Link to="/pricing" onClick={()=> window.scrollTo({top: 0, left: 0, behavior: 'instant'})}><button className="btn btn plan-btn">View Plans</button></Link> */}
                                </div>
                            </div>
                            <div className="col-md-6 ">
                                <img src={contactus} className="img-fluid" alt="24x7 Customer Support Team" />
                            </div>
                        </div>

                    </div>
                </div>


            </section>



            <section className="form-section">
                <div className="container">
                    <div className="warp-form">
                        <div className="row">
                            <div className="col-lg-6 col-md-12">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                    <div className="contact-form">
                                        <h3 className="">Get in Touch</h3>
                                        <p className="">We are here for you! How can we help?</p>

                                        <div className="mt-5">
                                            <input type="text" 
                                                    placeholder="Name" 
                                                    name="name"
                                                    id="name"
                                                    {...register('name', { required: "Name is required" })}
                                            />
                                            {errors.name && <span className="error">{errors.name.message}</span>}
                                        </div>

                                        <div className="mt-4">
                                            <input type="text"
                                                    placeholder="Email address" 
                                                    name="email"
                                                    {...register('email', {
                                                        required: 'Email is required',
                                                        pattern: {
                                                          value:  /^\b[A-Za-z0-9._%-]+@[A-Za-z0-9.-]+\.[a-zA-Z]{2,4}\b$/,
                                                        //   value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                                                          message: 'Please enter a valid email address',
                                                        },
                                                    })}
                                            />
                                            {errors.email && <span className="error">{errors.email.message}</span>}
                                        </div>

                                        <div className="mt-4 custom-field">
                                        <PhoneInput
                                                country="US"
                                                placeholder="Enter phone number"
                                                // value={value}
                                                international
                                                countryCallingCodeEditable={false}
                                                {...register('phone',{
                                                    required: "Phone number is required",
                                                    validate: {
                                                        validatephone: async (fieldValue) => {
                                                          return isValidPhoneNumber(fieldValue) || "Please enter a valid phone number";
                                                        },
                                                      },
                                                })}
                                                onChange={(newValue) => handleInputChange('phone', newValue)}
                                            />
                                            {/* <input type="text" 
                                                    placeholder="Phone number" 
                                                    name="phone"
                                                    {...register('phone',{
                                                        required: "Phone number is required",
                                                        pattern: {
                                                        //   value:  /^(1-?)?(\([2-9]\d{2}\)|[2-9]\d{2})-?[2-9]\d{2}-?\d{4}$/,
                                                        //   value:  /^[0-9]{10,12}$/,
                                                        //   value:  /^(?:\+\d{1,3}[. -]?)?(\([2-9]\d{2}\)|[2-9]\d{2})[. -]?[2-9]\d{2}[. -]?\d{4}$/,  //us phone validation
                                                        value:  /^(?:\+?\d{1,4}[. -]?)?(\([0-9]\d{2}\)|[0-9]\d{2})[. -]?[0-9]\d{2}[. -]?\d{4}$/,
                                                        message: 'Please enter a valid phone number',
                                                        },
                                                    })}
                                            /> */}
                                            {errors.phone && <span className="error">{errors.phone.message}</span>}

                                        </div>


                                        {/* <textarea rows="4" cols="50" placeholder="Go ahead, We are listening"  >
                                        </textarea> */}
                                        <div className="mt-4" >
                                            <textarea type="text" 
                                                    placeholder="Message" 
                                                    rows="9" 
                                                    name="message"
                                                    {...register('message', { required: 'Message is required' })}
                                            >
                                            </textarea>
                                            {errors.message && <span className="error">{errors.message.message}</span>}
                                        </div>
                                        <ReCAPTCHA
                                            ref={recaptchaRef}
                                            size="invisible"
                                            sitekey={process.env.REACT_APP_SITE_KEY}
                                        />
                                        <div className="d-grid gap-2 mt-4">
                                            <button className="btn btn-submit" type="submit">Submit</button>

                                        </div>

                                    </div>
                                </form>
                            </div>
                            <div className="col-lg-6 col-md-12 d-flex align-items-center">
                                <div className="form-rt ">
                                    <img src={formImg} className="img-fluid mb-5" alt="From Filling" />

                                        <div className="contact-adr">
                                            <ul>
                                                {/* <li><i className="fa-solid fa-location-dot"></i><span>838 Caantt Center, New York</span></li> */}
                                                {/* <li><i className="fa-solid fa-phone"></i><span>123-987-4567</span></li> */}
                                                {/* <li><i className="fa-solid fa-envelope"></i><span>swizzlesupport@gmail.com</span></li> */}
                                            </ul>
                                        </div>

                                </div>
                            </div>
                        </div>

                        <div className="social-media">
                            <ul>
                                <li className="mt-4">
                                    <a href="https://www.facebook.com/swizzleinnovations" target="_blank"><i className="fa-brands fa-facebook-f"></i></a>
                                </li>
                                <li className="mt-4">
                                    <a href="https://www.instagram.com/swizzleinnovations" target="_blank"><i className="fa-brands fa-instagram"></i></a>
                                </li>
                                <li className="mt-4">
                                    <a href="https://twitter.com/swizzleinnovate" target="_blank"><i class="fa-brands fa-x-twitter" target="_blank"></i></a></li>
                                <li className="mt-4">
                                    <a  href="https://www.linkedin.com/company/swizzleinnovations/" target="_blank"><i className="fa-brands fa-linkedin-in"></i> </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default Contact