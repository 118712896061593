import React, { useEffect } from 'react';
import banner_bg_a from '../Assets/Images/banner-bg-a.png';
import banner_bg_b from '../Assets/Images/banner-bg-b.png';
import logo_footer from '../Assets/Images/logo-footer.svg';
import fileflowlogo from '../Assets/Images/footer-light.svg';
import { Link, useLocation } from 'react-router-dom';
import swizzle_logo from '../Assets/Images/swizzle-logo.svg';
import fflogo from '../Assets/Images/ff-logo-footer.svg';
import sdklogo from '../Assets/Images/sdk-logo-a.svg';
// import { useHistory } from 'react-router-dom';
// import { Link } from "react-scroll";

function Footer({ScrollToSection}) {

    const fileflowUrl = process.env.REACT_APP_FILEFLOW_URL;

    const location = useLocation();
    // const history = useHistory();

    const scollSection = () => {
        if (location.hash) {
        const section = document.querySelector(location.hash);
        if (section) {
            section.scrollIntoView({ behavior: 'smooth' });
            // window.history.replaceState({}, document.title, window.location.pathname); // Remove the hash from the URL
        }
        }
    }

    useEffect(() => {
        scollSection();
    }, [location.hash]);

    return (
        <>
            <div className="footer">
                <div className="footer-line">
                    <div className="container">
                        <div className="row">
                            <div className="footer-box-align">
                                <div className="footer-banner shadow">
                                    <img className="banner-a" src={banner_bg_a} alt="fileflow-footer"/>
                                    <div className="text-center">
                                        <h6>Seamless. Limitless. Effortless.</h6>
                                        <h2 className="">Ready to move your team to <br /><span>Fileflow</span>?</h2>
                                        <Link to="/pricing"><button className="btn btn-primary btn-align">Get Started For Free</button></Link>
                                    </div>
                                    <img className="banner-b" src={banner_bg_b} alt="fileflow-footer"/>
                                </div>
                            </div>
                        </div>

                            <div className="footer-menu">
                                <div className="row">

                                    <div className="col-md-12 col-lg-3 justify-content-center align-items-center d-flex">
                                        <img className="img-fluid footer-logo" src={fileflowlogo} alt="fileflow-footer" />
                                    </div>
                                    

                                    <div className="col-md-3 col-lg-2">
                                        <h6>Product</h6>
                                         <ul>
                                            <li><Link to="/">Home</Link></li>
                                            <li><Link to="/solutions">Solutions</Link></li>
                                            <li><Link to="/pricing">Pricing</Link></li>
                                            <li><Link to="/contact">Contact</Link></li>
                                         </ul>
                                    </div>

                                    <div className="col-md-3 col-lg-2">
                                        <h6>Legal</h6>
                                         <ul>
                                          <li><a target="_blank" href="https://swizzleup.com/security-compliance">Security Compliance</a></li>
                                          <li><a target="_blank" href="https://swizzleup.com/termsandconditions">Terms of Use</a></li>
                                          <li><a target="_blank" href="https://swizzleup.com/privacypolicy">Privacy Policy</a></li>
                                        </ul>
                                    </div>

                                    <div className="col-md-3 col-lg-3">
                                        <h6>Solutions</h6>
                                        <ul>
                                            <li><Link to="/solutions#ClientEngagements" onClick={ScrollToSection}>For Client Engagements</Link></li>
                                            <li><Link to="/solutions#OrganizationalData" onClick={ScrollToSection}>For Corporate Data</Link></li>
                                            <li><Link to="/solutions#CreativeAssets" onClick={ScrollToSection}>For Marketing</Link></li>
                                        </ul>
                                    </div>
                                    <div className="col-md-3 col-lg-2">
                                        <h6>Swizzle Products </h6>
                                                    <ul className='mt-2 mb-0'>
                                                         <li><a className='pdt-icons  me-lg-4 me-md-2 me-3' href='/'><img src={fflogo} className="img-fluid" alt="Fileflow Logo"/></a></li>
                                                        <li><a className='pdt-icons  me-lg-4 me-md-2 me-3' href='https://successdesk.icwares.com/' target='_blank'><img src={sdklogo} className="img-fluid sdklogo" alt="SuccessDesk Logo"/></a></li>
                                                    </ul>
                                        <h6>Connect With Us</h6>
                                        <div className="footer-icons">
                                          <ul className="d-flex">
                                            
                                            <li><a className="bottom-menu-icons  me-lg-4 me-md-2 me-3" href="https://www.facebook.com/fileflow.by.swizzle/" target="_blank"><i className="fa-brands fa-facebook"></i></a></li>
                                            <li><a className="bottom-menu-icons  me-lg-4 me-md-2 me-3" href="https://www.instagram.com/swizzleinnovations" target="_blank"><i className="fa-brands fa-instagram"></i></a></li>
                                            {/* <li><a className="bottom-menu-icons  me-lg-4 me-md-2 me-3" href="/#"><i className="fa-brands fa-youtube" target="_blank"></i></a></li> */}
                                            <li><a className="bottom-menu-icons  me-lg-4 me-md-2 me-3" href="https://twitter.com/swizzleinnovate"><i class="fa-brands fa-x-twitter" target="_blank"></i></a></li>
                                            <li><a className="bottom-menu-icons  me-lg-4 me-md-2 me-3" href="https://www.linkedin.com/company/swizzleinnovations/" target="_blank"><i className="fa-brands fa-linkedin" target="_blank"></i></a></li>
                                         </ul>
                                      </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="bottom-menu">
                            <div className="row ">
                                {/* <div className='col-12'>
                                    <div className='text-lg-start text-center'>
                                        <img className="img-fuild my-4" src={fileflowlogo} alt=""></img>
                                    </div>
                                </div> */}

                                <div className="col-md-12 col-lg-6">
                                    <div class="product">
                                        <a target="_blank" href="https://swizzleup.com/">
                                          <img className="img-fluid " src={swizzle_logo} alt="logo-fileflow" />
                                          <p>A Product of Swizzle Innovations</p>
                                      </a>
                                   </div>
                                </div>

                                <div className="col-md-12 col-lg-6">
                                  <p class="pld"><span>&#169;</span>{new Date().getFullYear()} | Swizzle Innovations Pvt. Ltd. All Right Reserved</p>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
        </>
    )
}

export default Footer