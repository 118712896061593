import React, { useState,useEffect } from 'react';
import style from './getstarted.module.css';
import FileFlowLogo from '../Assets/getstarted/fileflow-logo.png';
import BannerDashboard from '../Assets/getstarted/banner-dashboard.png';
import Handsconnect from '../Assets/getstarted/handsconnect.png';
import Support from '../Assets/getstarted/support.png';
import Login from '../Assets/getstarted/login.png';
import FooterLogo from '../Assets/getstarted/footerlogo.png';
import Facebook from '../Assets/getstarted/facebook.svg';
import Instagram from '../Assets/getstarted/Instagram.svg';
import Linkedin from '../Assets/getstarted/Linkedin.svg';

import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';

function GetStarted() {

    var pricingURL = "https://fileflow.app/pricing";
    //  var urlExtension = '';
    //var fileflowUrl = process.env.REACT_APP_FILEFLOW_URL;
    const [formattedString, setFormattedString] = useState('');
     const queryParams = new URLSearchParams(decodeURIComponent(window.location.search));
            
     
   
    if(queryParams.size > 0){
        pricingURL = pricingURL+'?'+queryParams;
        // urlExtension = '?'+queryParams;
    }

  return (
    <>
      <Helmet>

          <title>Fileflow | Get Started</title>

            <meta
                name="description"
                content="Fileflow empowers seamless and secure sharing of your work and ideas using instant shareable links. Effortlessly upload files from your computer, ensuring easy saving and sharing."
            />
            <meta
                name="keywords"
                content="Cloud Storage ,Online File Storage,File Sharing and Collaboration,Secure Data Storage,Document Management,Cloud Backup Solutions,Virtual File Drive,Cloud Syncing Services,Access Files Anywhere,Online Document Repository,File Hosting Platform,Team Collaboration Tools,Cloud Workspace,Data Security and Privacy, Cross-Platform File Syncing,Cloud-Based Document Management,Centralized File Storage,Online Content Sharing,Document Version Control,24/7 Expert Support ,Email Support,Receive Large Files,Collaborative Workspaces,Advanced Search,Mobile Access,Unlimited Projects,Access Control,simple, Drag and Drop,User Groups "
            />
            <meta property="og:site_name" content="Fileflow" />
            <meta property="og:type" content="website" />

            <meta property="og:title" content="Fileflow | Get Started"/>
            <meta property="og:description" content="Fileflow empowers seamless and secure sharing of your work and ideas using instant shareable links. Effortlessly upload files from your computer, ensuring easy saving and sharing." />

             {/* <meta property="og:image" content="%PUBLIC_URL%/og/fileflow.png" /> */}
      </Helmet>
    <header>
        <div class="d-flex justify-content-between pt-4 mx-sm-5 mx-2 ">
            <img src={FileFlowLogo} class={`img-fluid ${style.fLogo}`} alt="Fileflow Logo"/>
            <div>
                {/* <Link to={`/contact`+urlExtension} class="btn btn-primary">Get Started For Free</Link> */}
                <a href={pricingURL} class={`btn ${style.btnPrimary}`}>Get Started For Free</a>
            </div>
        </div>
    </header>

    {/* header end  */}

    <section class={style.bannerwrapper}>
    {/* <section class='bannerwrapper'> */}
        <div class="container h-100">
            <div class="row align-items-center h-100">
                <div class="col-lg-5 order-1 order-lg-0">
                    <div class={`${style.headcontent} mt-lg-0 mt-md-4 mt-3`}>
                        <h1 class="main-head tracking-in-expand-fwd">The Future of File Sharing</h1>
                        <p class={style.bannerParagraph}>Transform your collaborative endeavours effortlessly, as we redefine the way you share and manage files.</p>
                        <ul class="p-0">
                            <li class="list_1"><span class="material-symbols-outlined me-2">done</span><p class={`my-1 ${style.textDark}`}>Keep your brand in front of your users.</p></li>
                            <li class="list_2"><span class="material-symbols-outlined me-2">done</span><p class={`my-1 ${style.textDark}`}>Collaborate seamlessly with your team.</p></li>
                            <li class="list_3"><span class="material-symbols-outlined me-2">done</span><p class={`my-1 ${style.textDark}`}>Securely store and send files.</p></li>
                        </ul>
                        <a href={pricingURL} class={`btn ${style.btnPrimary}`}>Get Started For Free</a>
                    </div>
                </div>
                <div class="col-lg-7 order-0 order-lg-1">
                    <div class={style.imageContainerBody}>
                        <img class="img-fluid" src={BannerDashboard} alt="Fileflow Dashboard"/>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class={style.featureWrapper}>
        <div class="container">
            <div class={`row ${style.featurerow}`}>
                <div class="col-lg-7">
                    <div class={`${style.card} main ${style.wlcomeDashboard}`}>
                        <div class={style.headcontent}>
                            <h6 class="">White Label Branding</h6>
                            <h4>Keep your brand in <br/>front of your users.</h4>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class={`${style.card} ${style.seamlessCollab}`}>
                        <div class={style.headcontent}>
                            <h6>Seamless Collaboration</h6>
                            <h4>Collaborate seamlessly<br/> with your team.</h4>
                        </div>
                    </div>
                </div>
                <div class="col-lg-5">
                    <div class={`${style.card} ${style.shareupFiles}`}>
                        <div class={style.headcontent}>
                            <h6>Store And share files</h6>
                            <h4>Securely store <br/>and send files.</h4>
                        </div>
                    </div>
                </div>
                <div class="col-lg-7">
                    <div class={`${style.card} ${style.uiInterface}`}>
                        <div class={style.headcontent}>
                            <h6>Intuitive User Interface</h6>
                            <h4>Effortlessly navigate through files, <br/>and enhance collaboration.</h4>
                        </div>
                    </div>    
                </div>
            </div>
        </div>
    </section>

    <section class={style.capabilitiesWarpper}>
        <div class="container">
            <div class={`${style.headcontent} text-center`}>
                <h2 class="mb-4">Key Capabilities</h2>
                <p>Experience unparalleled efficiency as Fileflow's key capabilities redefine how you manage, collaborate, and share files seamlessly </p>
            </div>
            <div class="row mt-3">
                <div class="col-lg-4 col-md-6 col-12">
                    <div class={style.headRow}>
                        <div class={style.innerBlock}>
                            <span class={style.hands}></span>
                            <h5>Unlimited External Collaborators</h5>
                        </div>
                        <p>Foster collaboration without boundaries by inviting as many external collaborators, ensuring seamless teamwork beyond organizational limits.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class={style.headRow}>
                        <div class={style.innerBlock}>
                            <span class={style.userGroup}></span>
                            <h5>Unlimited User Groups</h5>
                        </div>
                        <p>Tailor your collaboration with unlimited user groups, facilitating organized and efficient communication among different teams or projects.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class={style.headRow}>
                        <div class={style.innerBlock}>
                            <span class={style.lock}></span>
                            <h5>Access Expiration & Passwords</h5>
                        </div>
                        <p>Exercise precise control over who can access, edit, or view files with granular permissions, securing your data and ensuring confidentiality.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class={style.headRow}>
                        <div class={style.innerBlock}>
                            <span class={style.accessKey}></span>
                            <h5>Access Expiration & Passwords</h5>
                        </div>
                        <p>Enhance file security with customizable access expiration and password protection, providing an extra layer of control over sensitive information.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class={style.headRow}>
                        <div class={style.innerBlock}>
                            <span class={style.comments}></span>
                            <h5>Commenting & Notification</h5>
                        </div>
                        <p>Foster real-time collaboration through commenting features, coupled with notifications, to keep your team updated and engaged throughout the workflow.</p>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-12">
                    <div class={style.headRow}>
                        <div class={style.innerBlock}>
                            <span class={style.customerSupport}></span>
                            <h5>24/7 Technical Support</h5>
                        </div>
                        <p>Enjoy peace of mind with our dedicated 24/7 technical support, ensuring assistance whenever you need it, and a smooth experience on the  platform.</p>
                    </div>
                </div>
                <div class="text-center mb-0">
                    <a href={pricingURL} class={`btn ${style.btnPrimary}`}>Get Started For Free</a>
                </div>
            </div>
        </div>

    </section>

    <section class={style.collabWrapper}>
        <div class="container">
            <div class={style.card}>
                <div class="row align-items-center">
                    <div class="col-lg-6 order-lg-0 order-1">
                        <div class={style.headcontent}>
                            <h2 class="mb-0">Effortless <span class={style.highlight}>collaboration</span> meets powerful <span class={style.highlight}>functionality</span></h2>
                            <p class="my-3">Experience a new standard in file management, ensuring productivity and innovation at every click.</p>
                            <a href={pricingURL} class={`btn ${style.btnPrimary}`}>Get Started For Free</a>
                        </div>
                    </div>
                        <div class="col-lg-6 order-lg-1 order-0">
                            <div class={style.imageContainerBody}>
                                <img class="img-fluid" src={Handsconnect} alt="Fileflow Collaboration"/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
    </section>

    <section class={style.supportWrapper}>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class={style.imageContainerBody}>
                        <img class="img-fluid" src={Support} alt="Fileflow Support"/>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class={`${style.headcontent} mt-lg-0 mt-4`}>
                        <h2>24/7 Expert Support</h2>
                        <p>At Fileflow, we understand the importance of immediate assistance. That's why our live chat support takes center stage, offering you a direct and real-time channel for quick and seamless communication.</p>
                    </div>
                    <div class={style.supportBlocker}>
                        <small><span class="material-symbols-outlined me-2">forum</span>Chat Support</small>
                        <small><span class="material-symbols-outlined me-2">mail</span>Email Support</small>
                    </div>
                </div>
            </div>
        </div>
    </section>

    <section class={style.joinusWrapper}>
        <div class="container">
            <div class="row align-items-center pt-5 px-sm-0 px-2">
                <div class="col-lg-6">
                    <div class="text-lg-start text-center">
                        <img class="img-fluid" src={Login} alt="Fileflow Login"/>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class={`${style.headcontent} ${style.joinusInner} my-lg-0 my-5`}>
                        <h2 class="mb-4">Ready to give <span class={style.highlight}>Fileflow</span> a try?</h2>
                        <a href={pricingURL} class={`btn ${style.btnPrimary} me-3`}>Get Started For Free</a>
                        <Link to="/contact" class={`btn ${style.btnOutlinePrimary}`}>Contact Us</Link>
                    </div>
                </div>
            </div>
        </div>
    </section>

    {/* footer start */}

    <footer class={style.footerBody}>
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-3 col-12">
                    <img class={`img-fluid ${style.fLogo}`} src={FooterLogo} alt="Fileflow by swizzle logo"/>
                </div>
                <div class="col-lg-6 col-12">
                    <div class={style.ourPolicy}>
                        <ul>
                            <li><a href="https://swizzleup.com/privacypolicy" target="_blank">Privacy Policy</a></li>
                            <li><a href="https://swizzleup.com/termsandconditions" target="_blank">Terms & Conditions</a></li>
                            <li><a href="https://fileflow.app/contact" target="_blank">Contact Us</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-3 col-12">
                    <div class={style.socialMediaBody}>
                        <ul>
                            <li><a href="https://www.facebook.com/fileflow.by.swizzle/" target="_blank"><img class="img-fluid" src={Facebook} alt=""/></a></li>
                            <li><a href="https://www.instagram.com/swizzleinnovations" target="_blank"><img class="img-fluid" src={Instagram} alt=""/></a></li>
                            <li><a href="https://www.linkedin.com/company/swizzleinnovations/" target="_blank"><img class="img-fluid" src={Linkedin} alt=""/></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </footer>
    </>
  )
}

export default GetStarted